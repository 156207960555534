body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div::-webkit-scrollbar {
  display: none;
}

div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.full-width {
  min-width: 100%;
}

.full-height {
  min-height: 100%;
}

.full-height-viewport {
  height: 100vh;
}

.filter-white {
  filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg)
    brightness(106%) contrast(106%);
}

.color-white {
  color: white imo !important;
}

.drop-shadow {
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.5)) !important;
}

.ant-popover {
  z-index: 300 !important;
}

@font-family: Montserrat;@border-radius-base: 5px;@primary-color: #EA3656;@btn-font-weight: 700;@btn-font-size: 15px;